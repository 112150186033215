import { useState, useEffect, createRef, useContext, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js'
import { showToast } from '../tools/Toast.js'
import { checkName, formatPhone } from '../tools/StringTools.js'

import CustomBottomSheet from './CustomBottomSheet.js'
import PhoneInput from 'react-phone-number-input'
import { parsePhoneNumber } from 'react-phone-number-input/max'
import 'react-phone-number-input/style.css'
import PuffLoader from "react-spinners/PuffLoader";

function LoginAndVerify(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [step, setStep] = useState(1);
	const [phone, setPhone] = useState(null);
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [customer, setCustomer] = useState(null);
	const [codeRefsArray] = useState(() =>
		Array.from({ length: 4 }, () => createRef())
	);
	const [codeIndex, setCodeIndex] = useState(0);
	const [code, setCode] = useState(() =>
		Array.from({ length: 4 }, () => "")
	);
	const [codeTimer, setCodeTimer] = useState(0);
	const [loading, setLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState(null);
	const [navigateTo, setNavigateTo] = useState(null);
	const [dismissable, setDismissable] = useState(true);

	const phoneInput = useRef();
	const firstnameInput = useRef();
	const lastnameInput = useRef();
	
	const onClose = useCallback(() => {
		if(navigateTo != null)
			navigate(navigateTo);
	}, [navigateTo]);
	
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleCodeChange = (value) => {
		setCodeIndex((prevIndex) => {
			var nextIndex, nextInput;
			if(value != "") {
				nextIndex = prevIndex < 3 ? prevIndex + 1 : prevIndex;
				if(nextIndex == 3) {
					var codeComplete = true;
					var i = 0;
					while((i < nextIndex) && codeComplete) {
						if(code[i] == "")
							codeComplete = false;
						i = i + 1;
					}
					if((prevIndex == 3) && codeComplete)
						next();
				}
			}
			else {
				nextIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
			}
			nextInput = codeRefsArray?.[nextIndex]?.current;
			nextInput.focus();
			return nextIndex;
		});
	};
	
	const checkPhone = () => {
		if(phone != null) {
			const phoneNumber = parsePhoneNumber(phone)
			if (phoneNumber) {
				if(phoneNumber.getType() == "MOBILE")
					return true;
			}
		}
		return false;
	};
	
	const checkFirstnameLastname = () => {
		let check = true;
		if(!checkName(firstname)) {
			firstnameInput.current.className = firstnameInput.current.className + " border-error";
			check = false;
		}
		if(!checkName(lastname)) {
			lastnameInput.current.className = lastnameInput.current.className + " border-error";
			check = false;
		}
		return check;
	};
	
	const next = () => {
		switch(step) {
			case 1:
				if(checkPhone()) {
					sendCode();
					setStep(2);
				}
				else {
					phoneInput.current.className = phoneInput.current.className + " border-error";
					showToast(t("login.errorPhoneMobile"));
				}
				break;
		
			case 2:
				verifCode();
				break;
			case 3:
				if(checkFirstnameLastname()) {
					searchCustomer();
				}
				else {
					showToast(t("toast.errorInput"));
				}
				break;
			case 4:
				if(checkFirstnameLastname()) {
					addCustomer();
				}
				else {
					showToast(t("toast.errorInput"));
				}
				break;
		}
	}
	
	const sendCode = () => {
		Api(
			"verifSMS",
			{
				"destinataire": formatPhone(phone)
			},
			(data) => {},
			(idError) => {},
			globalState.token,
			globalState.setToken
		);
	};
	
	const verifCode = () => {
		setLoading(true);
		Api(
			"verifSMS",
			{
				"destinataire": formatPhone(phone),
				"code": getCodeAsString()
			},
			(data) => {
				if(data)
					searchCustomer();
			},
			(idError) => {
				setLoading(false);
				switch(idError) {
					case 55:
						showToast(t("login.errorCodeInvalid"));
						break;
					case 56:
						showToast(t("login.errorCodeExpired"));
						break;
					case 57:
						showToast(t("login.errorCodeAlready"));
						break;
					default:
						showToast(t("toast.oopsError"));
						break;
				}
			},
			globalState.token,
			globalState.setToken
		);
	};
	
	const searchCustomer = () => {
		setLoading(true);
		if(step == 2) {
			setLoadingMessage(t("login.loadingDefault"));
			Api(
				"rechercheClientByTelephone",
				{
					"telephone": formatPhone(phone)
				},
				(data) => {
					setCustomer(data);
					login(data);
				},
				(idError) => {
					setLoading(false);
					switch(idError) {
						case 52:
							setStep(4);
							break;
						case 202:
							setStep(3);
							break;
						default:
							showToast(t("toast.oopsError"));
							break;
					}
				},
				globalState.token,
				globalState.setToken
			);
		}
		else {
			setDismissable(false);
			setLoadingMessage(t("login.loadingCustomer", {firstname: firstname}));
			Api(
				"rechercheClient",
				{
					"prenom": firstname,
					"nom": lastname,
					"telephone": formatPhone(phone)
				},
				(data) => {
					setCustomer(data);
					login(data);
				},
				(idError) => {
					setLoading(false);
					setDismissable(true);
					switch(idError) {
						case 52:
							addCustomer();
							break;
						case 202:
							setStep(3);
							break;
						default:
							showToast(t("toast.oopsError"));
							break;
					}
				},
				globalState.token,
				globalState.setToken
			);
		}
	};
	
	const addCustomer = () => {
		setLoading(true);
		setDismissable(false);
		Api(
			"addClient",
			{
				"prenom": firstname,
				"nom": lastname,
			},
			(data) => {
				getCustomer();
			},
			(idError) => {
				setLoading(false);
				setDismissable(true);
				showToast(t("toast.oopsError"));
			},
			globalState.token,
			globalState.setToken
		);
	};
	
	const login = (customer) => {
		setLoading(true);
		setDismissable(false);
		if((firstname.length > 0) || (customer.prenom?.length > 1))
			setLoadingMessage(t("login.loadingCustomer", {firstname: (firstname.length > 0 ? firstname : customer.prenom)}));
		Api(
			"loginClientByVerifSMS",
			{
				"id_client": customer.idClient,
				"telephone": formatPhone(phone),
				"code": getCodeAsString()
			},
			(data) => {
				getCustomer();
			},
			(idError) => {
				setLoading(false);
				setDismissable(true);
				switch(idError) {
					case 56:
						showToast(t("toast.oopsError"));
						setCode(['','','','']);
						setCodeIndex(0);
						setLoadingMessage(null);
						setDismissable(true);
						setStep(1);
						break;
					default:
						showToast(t("toast.oopsError"));
						break;
				}
			},
			globalState.token,
			globalState.setToken
		);
	};
	
	const updateCart = () => {
		globalState.cart.updateCart(
			false, 
			globalState, 
			setGlobalState, 
			(_) => {
				setNavigateTo('/delivery');
				setDismissable(true);
				props.setIsOpen(false);
			}, 
			(_) => {
				setNavigateTo('/delivery');
				setDismissable(true);
				props.setIsOpen(false);
			}
		);
	};
	
	const getCustomer = () => {
		setLoading(true);
		Api(
			"getClientToken", 
			{},
			(data) => {
				setGlobalState(prevState => ({
					...prevState,
					customer: data,
					loginPhone: formatPhone(phone),
				}));
			},
			(idError) => {
				setLoading(false);
				setDismissable(true);
				showToast(t("toast.oopsError"));
			},
			globalState.token,
			globalState.setToken,
		);
	};
	
	const getCodeAsString = () => {
		let codeConcat = "";
		code.forEach((c, i) => {
			codeConcat = codeConcat + c;
		});
		return codeConcat;
	}
	
	useEffect(() => {
		if(step == 2)
			codeRefsArray?.[0]?.current.focus();
	}, [step]);
	
	useEffect(() => {
		if(codeTimer > 0) {
			setTimeout(() => {
				setCodeTimer(codeTimer - 1);
			}, 1000);
		}
	}, [codeTimer]);
	
	useEffect(() => {
		setStep(1);
		setLoading(false);
		setCode(['','','','']);
		setCodeIndex(0);
		setCodeTimer(0);
		setLoadingMessage(null);
		setDismissable(true);
	}, [props.resetView]);
	
	useEffect(() => {
		if(globalState.customer != null) {
			// Back from getCustomer, login is complete
			updateCart();
		}
	}, [globalState.customer]);
	
	const renderSwitch = () => {
		switch(step) {
			case 1:
				return(
				   <form>
					  <div className="text-start">
						 <p className="lh-base pb-2 text-muted fs-6 mb-1">{t("login.enterPhone")}</p>
						  <div className="input-group bg-white py-2 px-4 mb-4" ref={phoneInput}>
							<PhoneInput
								className="form-control"
								id="phone"
								name="phone"
								value={phone}
								onChange={phone => {
									setPhone(phone);
									phoneInput.current.className = phoneInput.current.className.replace(" border-error", "");
								}}
								onKeyDown={(e) => {
									if(e.key == "Enter") {
										e.preventDefault();
										next();
									}
								}}
								initialValueFormat="national"
								defaultCountry="FR"
								countrySelectProps={{className: "disabled"}}
								addInternationalOption={false}
								ref={phoneInput}
							 />
						  </div>
						 <Link className="btn btn-danger fw-bold py-3 px-4 w-100 rounded-4 shadow" onClick={() => { next(); }} >{t("button.next")}</Link>
						 <div className="mt-3 d-flex flex-column align-items-center">
							<p className="text-muted mb-1 text-center">
								{t("login.accept")}
								<span className="ms-1">
									<Link className="text-info " onClick={(e) => { setNavigateTo(t("cms.privacy.url")); props.setIsOpen(false); }}>{t("cms.privacy")}</Link>
								</span>
							</p>
						 </div>
					  </div>
				   </form>
				);
			case 2:
				return(
				   <form>
					  <div className="text-start">
						 <p className="lh-base pb-2 text-muted fs-6 mb-1">{t("login.enterCode")}</p>
						 <div className="row mb-4">
							{codeRefsArray.map((ref, index) => {
								return (
									<div className="col-3" key={index}>
									   <input 
											ref={ref} 
											type="number"
											pattern="\d*"
											maxLength="1"
											className="form-control text-center bg-white shadow-sm rounded-3 border py-2 fs-4"
											value={code[index]}
											onChange={(e) => { 
												const { value } = e.target;
												var newCode = [...code];
												code.forEach((c, i) => {
													if(i == index) {
														newCode[i] = value;
														code[i] = value;
													}
													
												});
												setCode(newCode);
												handleCodeChange(value);
											}}
											onClick={(e) => {
											  setCodeIndex(index);
											  e.target.select();
											}}
											maxLength="1"
										/>
									</div>
								);
							})}
						 </div>
						 <Link className="btn btn-danger fw-bold py-3 px-4 w-100 rounded-4 shadow" onClick={() => { next(); }}>{t("button.next")}</Link>
						 <div className="mt-3 d-flex justify-content-center">
							<span className="small">
							{(codeTimer == 0) &&
								<Link onClick={(e) => { setCode(['','','','']); setCodeIndex(0); sendCode(); setCodeTimer(60); }} className="text-info me-2">{t("login.resend")}</Link>
							}
							{(codeTimer > 0) &&
								<small className="text-muted">{t("login.resend")} ({t("login.resendIn")} <b id="timer">{codeTimer}</b> {t("login.resendSecs")})</small>
							}
							</span>
						 </div>
					  </div>
				   </form>
				);
			case 3:
			case 4:
				return(
				   <form>
					  <div className="text-start">
						 <p className="lh-base pb-2 text-muted fs-6 mb-1">{t("login.enterInfos")}</p>
						  <div className="row g-3 mb-3">
							 <div className="col-6">
								<div className="form-floating h-100">
								   <input 
										type="text"
										className="form-control"
										id="firstname"
										name="firstname"
										placeholder="firstname"
										value={firstname}
										onChange={(e) => { 
											setFirstname(e.target.value);
											firstnameInput.current.className = firstnameInput.current.className.replace(" border-error", "");
										}}
										onBlur={(e) => {
											setFirstname(e.target.value.trim());
										}}
										ref={firstnameInput}	
									/>
								   <label htmlFor="firstname">{t("form.firstname")}</label>
								</div>
							 </div>
							 <div className="col-6">
								<div className="form-floating h-100">
									<input 
										type="text"
										className="form-control"
										id="lastname"
										name="lastname"
										placeholder="lastname"
										value={lastname}
										onChange={ (e) => { 
											setLastname(e.target.value);
											lastnameInput.current.className = lastnameInput.current.className.replace(" border-error", "");
										}}
										onBlur={(e) => {
											setLastname(e.target.value.trim());
										}}
										onKeyDown={(e) => {
											if(e.key == "Enter") {
												e.preventDefault();
												setLastname(e.target.value.trim());
												next();
											}
										}}
										ref={lastnameInput} />
									<label htmlFor="lastname">{t("form.lastname")}</label>
								</div>
							 </div>
						  </div>
						 <Link className="btn btn-danger fw-bold py-3 px-4 w-100 rounded-4 shadow" onClick={() => { next(); }} >{t("button.next")}</Link>
					  </div>
				   </form>
				);
		}	
	};

	return(
		<CustomBottomSheet id="login" type="auto" isOpen={props.isOpen} setIsOpen={props.setIsOpen} onClose={onClose} dismissable={dismissable} noPadding={true} autoWidth={true}>
			<div className="row g-0">
				<div className="col-lg-4 d-none d-lg-block">
				   <div className="p-4 d-flex bg-info align-items-center flex-column text-center justify-content-center h-100">
					  <img src={require('../img/Logo_V2_sans_etiquette_blanc.png')} alt="" className="img-fluid mb-4 px-2"/>
					  <div>
						 <h5 className="fw-bold">{t("login.welcome")}</h5>
					  </div>
				   </div>
				</div>
				<div className="col-lg-8 p-4">
				   <div className="mb-4 pe-5 d-none d-lg-block"></div>
					{loading &&
						<div className="d-flex align-items-center justify-content-center h-100 pb-4 flex-column">
							{loadingMessage != null &&
								<p className="text-info text-center">{loadingMessage}</p>
							}
							<PuffLoader color="#1D71B8" />
						</div>
					}
					{!loading && renderSwitch()}
				</div>
			</div>
		</CustomBottomSheet>
	);
}
export default LoginAndVerify;

