import { useContext, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js'
import { showToast } from '../tools/Toast.js'
import { currencyFormat } from '../tools/NumberTools.js'

import AddAddress from '../components/AddAddress.js'
import MyAddresses from '../components/MyAddresses.js'
import AddressItem from '../components/AddressItem.js'
import DeliveryConfirm from '../components/DeliveryConfirm.js'
import LoadingButton from '../components/LoadingButton.js'

function DeliveryAddress(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	
	const [address, setAddress] = useState(null);
	const [addressToEdit, setAddressToEdit] = useState(null);
	const [openAddAddress, setOpenAddAddress] = useState(false);
	const [openDeliveryConfirm, setOpenDeliveryConfirm] = useState(false);
	const [nextButtonLoading, setNextButtonLoading] = useState(false);
	
	const addAddressButton = useRef();
	
	const { t } = useTranslation();
	const navigate = useNavigate();
	
	const next = () => {
		if(globalState.cart.idCart == 0) {
			showToast(t("toast.oopsError"));
			navigate('/cart');
		}
		else if(address != null) {
			getDeliveryMinAndFee();
		}
		else {
			showToast(t("deliveryAddress.errorNoAddress"));
		}
	};
	
	const acceptDeliveryConfirm = () => {
		navigate("/payment");
	};
	
	const getDeliveryMinAndFee = () => {
		setNextButtonLoading(true);
		globalState.cart.idAddress = address.idAdresse;
		globalState.cart.getDeliveryMinAndFee(
			globalState,
			setGlobalState,
			(data) => {
				if(globalState.cart.getTotalProducts() < data.minimumCommande) {
					showToast(t("toast.minOrderForAddress", { "min": currencyFormat(data.minimumCommande) }));
					setNextButtonLoading(false);
				}
				else {
					getBags();
				}
			},
			(idError) => {
				setNextButtonLoading(false);
				if(idError == 66) {
					showToast(t("toast.addressOutsideRange"));
				}
				else {
					showToast(t("toast.oopsError"));
				}
			}
		);
	};
	
	const getBags = () => {
		globalState.cart.getBags(
			globalState,
			setGlobalState,
			(data) => {
				updateCart();
			},
			(idError) => {
				setNextButtonLoading(false);
				showToast(t("toast.oopsError"));
			}
		);
	};
	
	const updateCart = () => {
		globalState.cart.updateCart(
			true, 
			globalState, 
			setGlobalState, 
			(data) => {
				setOpenDeliveryConfirm(true);
				setNextButtonLoading(false);
			}, 
			(idError) => {
				setNextButtonLoading(false);
				switch(idError) {
					case 11:
					case 25:
					case 28:
						setNextButtonLoading(true);
						getCustomer(
							(_) => {
								showToast(t("toast.oopsError"));
								setNextButtonLoading(false);
							},
							(_) => {
								showToast(t("toast.oopsError"));
								setNextButtonLoading(false);
							}
						);
						break;
					case 34:
					case 35:
					case 42:
						showToast(t("toast.errorCartProduct"));
						navigate('/cart');
						break;
					case 51:
						showToast(t("toast.errorMinOrder"));
						break;
					case 62:
						setNextButtonLoading(true);
						getCustomer(
							(_) => {
								showToast(t("toast.errorAddressUnavailable"));
								setNextButtonLoading(false);
							},
							(_) => {
								showToast(t("toast.oopsError"));
								setNextButtonLoading(false);
							}
						);
						break;
					case 66:
						showToast(t("toast.addressOutsideRange"));
						break;
					default:
						if(globalState.cart.idCart > 0) {
							globalState.cart.idCart = 0;
							setGlobalState(prevState => ({
								...prevState,
								cart: globalState.cart,
							}));
							updateCart();
						}
						else {
							showToast(t("toast.oopsError"));
						}
						break;
				}
			}
		);
	};
	
	const getCustomer = (onSuccess, onFailure) => {
		Api(
			"getClientToken", 
			{},
			(data) => {
				setGlobalState(prevState => ({
					...prevState,
					customer: data
				}));
				onSuccess();
			},
			(idError) => {
				setGlobalState(prevState => ({
					...prevState,
					customer: null
				}));
				onFailure();
			},
			globalState.token,
			globalState.setToken,
		);
	};
	
	useEffect(() => {
		if(globalState != null) {
			if(globalState.customer == null) {
				navigate('/cart');
			}
			else if(Array.isArray(globalState.customer.adresses) && (globalState.customer.adresses.length > 0)) {
				let address = globalState.cart.getAddress(globalState) ?? globalState.customer.adresses[0];
				setAddress(address);
			}
		}
	}, [globalState?.customer, globalState?.cart.idAddress]);
	
	useEffect(() => {
		if((globalState?.customer != null) && (globalState.customer.adresses.length == 0)){
			setOpenAddAddress(true);
		}
	}, [globalState?.customer]);
	
	useEffect(() => {
		props.setReturnPage("/cart");
		props.setPageTitle(t("deliveryAddress.title"));
	}, []);

	return(
		<div className="vh-100 my-auto overflow-auto">
			<div className="row osahan-my-account-page border-secondary-subtle overflow-auto g-0">
				<div className="px-4 py-3">
				   <h5 className="offcanvas-title fw-bold">{t("deliveryAddress.title")}</h5>
				</div>
				<div className="col-lg-8 border-end">
					<div className="bg-light">
						{(globalState?.customer != null) && (globalState.customer.adresses.length > 1) &&
						  <Link data-bs-toggle="offcanvas" data-bs-target="#myaddress" aria-controls="myaddress" className="link-dark py-3 px-4 osahan-location text-decoration-none d-flex align-items-center gap-3 text-start flex-shrink-0">
							 <i className="bi bi-arrow-left-right fs-4 text-info"></i>
							 <div className="lh-sm">
								<h6 className="fw-bold mb-0">{t("deliveryAddress.switchAddress")}</h6>
							 </div>
						  </Link>
						}
						{(globalState?.customer != null) && (globalState.customer.adresses.length <= 1) &&
						  <Link className="link-dark py-3 px-4 osahan-location text-decoration-none d-flex align-items-center gap-3 text-start flex-shrink-0" onClick={(e) => { setAddressToEdit(null); setOpenAddAddress(true);}} ref={addAddressButton}>
							 <i className="bi bi-plus-circle fs-4 text-info"></i>
							 <div className="lh-sm">
								<h6 className="fw-bold mb-0">{t("deliveryAddress.addAddress")}</h6>
							 </div>
						  </Link>
						}
				   </div>
					<div className="px-4 py-2 bg-light">
						{(address != null) &&
						  <div className="w-100 bg-white border rounded-4">
							<div>
								<div className="d-flex align-items-center justify-content-between bg-white p-3">
								   <div className="w-75">
									  <div className="d-flex align-items-center gap-3 osahan-mb-1">
										 <i className="bi bi-person fs-5"></i>
										 <div className="pe-4 overflow-hidden">
											<h6 className="fw-bold mb-1">{address.prenom + " " + address.nom}</h6>
										 </div>
									  </div>
								   </div>
								   <div className="ms-auto gap-2 text-center">
									  <Link className="d-flex align-items-center gap-1 text-info"><i className="bi bi-pencil-square d-flex" onClick={(e) => { setAddressToEdit(address); setOpenAddAddress(true); }}></i></Link>
								   </div>
								</div>
								<div className="d-flex align-items-center justify-content-between bg-white p-3">
								   <div className="w-75">
									  <div className="d-flex align-items-center gap-3 osahan-mb-1">
										 <i className="bi bi-house fs-5"></i>
										 <div className="pe-4 overflow-hidden">
											<h6 className="fw-bold mb-1">{address.adresse}</h6>
											<p className="text-muted text-truncate mb-0 small">{address.cp + " " + address.ville}</p>
										 </div>
									  </div>
								   </div>
								   <div className="ms-auto gap-2 text-center">
									  <Link className="d-flex align-items-center gap-1 text-info"><i className="bi bi-pencil-square d-flex" onClick={(e) => { setAddressToEdit(address); setOpenAddAddress(true); }}></i></Link>
								   </div>
								</div>
								<div className="d-flex align-items-center justify-content-between bg-white p-3">
								   <div className="w-75">
									  <div className="d-flex align-items-center gap-3 osahan-mb-1">
										 <i className="bi bi-info-square fs-5"></i>
										 <div className="pe-4 overflow-hidden">
											<h6 className="fw-bold mb-1">{address.complementAdresse}</h6>
										 </div>
									  </div>
								   </div>
								   <div className="ms-auto gap-2 text-center">
									  <Link className="d-flex align-items-center gap-1 text-info"><i className="bi bi-pencil-square d-flex" onClick={(e) => { setAddressToEdit(address); setOpenAddAddress(true); }}></i></Link>
								   </div>
								</div>
								<div className="d-flex align-items-center justify-content-between bg-white p-3">
								   <div className="w-75">
									  <div className="d-flex align-items-center gap-3 osahan-mb-1">
										 <i className="bi bi-telephone fs-5"></i>
										 <div className="pe-4 overflow-hidden">
											<h6 className="fw-bold mb-1">{address.telephone}</h6>
										 </div>
									  </div>
								   </div>
								   <div className="ms-auto gap-2 text-center">
									  <Link className="d-flex align-items-center gap-1 text-info"><i className="bi bi-pencil-square d-flex" onClick={(e) => { setAddressToEdit(address); setOpenAddAddress(true); }}></i></Link>
								   </div>
								</div>
							</div>
						</div>
						}
				   </div>
				</div>
				<div className="col-lg-4 bg-light p-4">
					<LoadingButton title={t("button.next")} loading={nextButtonLoading} onClick={(e) => next()} />
				</div>
			</div>
			<AddAddress isOpen={openAddAddress} setIsOpen={setOpenAddAddress} address={addressToEdit} />
			<MyAddresses openAddAdress={() => setOpenAddAddress(true)} addressSelected={address} setAddressToEdit={setAddressToEdit} />
			<DeliveryConfirm isOpen={openDeliveryConfirm} setIsOpen={setOpenDeliveryConfirm} address={address} acceptDeliveryConfirm={acceptDeliveryConfirm} />
		</div>
	);
}
export default DeliveryAddress;